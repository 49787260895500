<template>
    <div>
        
        <form @submit.prevent="Enviar()" id="formulario">

            <div class="alert alert-danger" v-if="error" style="font-size:12pt;">{{error}}</div>

            <label>Nome*</label>
            <input type="text" class="form-control" v-model="formulario.nome" required>

            <label>E-mail*</label>
            <input type="text" class="form-control" v-model="formulario.email" required>

            <label>Telefone</label>
            <input type="text" class="form-control" v-model="formulario.telefone" required>

            <label>Qual o tamanho da sua frota?</label>
            <select class="form-control" v-model="formulario.frota" required>
                <option value="">Selecione uma opção</option>
                <option value="1-20">1 - 20 conjuntos</option>
                <option value="21-50">21 - 50 conjuntos</option>
                <option value="51-100">51 - 99 conjuntos</option>
                <option value="+100">+100 conjuntos</option>
            </select>
            <input type="submit" class="btn btn-success" value="Quero receber uma demonstração" style="margin-top:10px;width:100%;font-size:14pt;">

        </form>

        <!-- Modal -->
        <div class="modal fade" id="success" tabindex="-1" role="dialog" aria-labelledby="success" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Solicitação enviada</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    Olá, <br><br>
                    seu pedido de demonstração foi enviado com sucesso.<br><br> 
                    Em breve entraremos em contato com você.
                </p>
            </div>
            </div>
        </div>
        </div>

    </div>
</template>

<script>
import $ from 'jquery'

export default {
    name:"formulario",
    data(){
        return{
            formulario:{},
            error:''
        }
    },
    methods:{
        Enviar(){
            this.$axios.post("https://www.aetonline.com.br/api/contatoLP",this.formulario).then((response) =>{
                console.log(response.data)

                if(response.data.error==true){
                    let messages = response.data.message
                    let message = Object.keys(messages).map((item) => {
                        return messages[item][0]
                    });

                    this.error = message[0];
                }else{
                    $('#success').modal('show')
                    this.formulario = {}
                }

            });
        }
    }
}
</script>