<template>
  <div id="app">

    <div class="container">
      <div class="row justify-content-center">

        <div class="col-lg-7 col-md-7">

          <img src="assets/imgs/logo.jpg" alt="" style="height:50px;display:block;margin-left:auto;margin-right:auto;margin-top:25px;">

            <h1 style="text-align:center;font-size:24pt;margin-top:25px;margin-bottom:25px;">
              <!--Conheça aetonline. <br>-->Ponha um ponto final em seus problemas para liberação 
              de AETs junto ao DNIT e DERs, em todo o Brasil.
            </h1>

            <div style="border-radius:7px;border:solid 3px #CFDAE4;background: #F0F5F9;">
              <!-- 16:9 aspect ratio -->
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" style="border-radius:5px;" src="https://www.youtube.com/embed/iRAE-Hf5fwE?autoplay=1&controls=1&iv_load_policy=3&modestbranding=1&rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>

            <p class="center" style="margin-top:25px;">Com nossa solução você automatiza o trabalho,<br> <strong>economiza tempo e dinheiro</strong>.</p>
            <div class="center" style="margin-top:25px;">
               
            <button class="btn btn-action center" style="line-height:130%;padding:25px;padding-top:15px;padding-bottom:15px;" v-scroll-to="'#formulario'">
              <span style="font-size:16pt;font-weight:500;">Teste gratuitamente</span><br>
              <span style="font-size:9pt;font-weight:500">Damos todo o suporte para você começar</span>
            </button>

            </div>

            <div style="">
              <div class="row" style="margin-top:50px;">
           
                <div class="col-lg-12 col-md-12">

                  <div class="" style="padding:25px;border-radius:10px;background:#fff2e1;margin-bottom:25px;">
                    <p style="margin-bottom:0px;">
                      Você cadastra os veículos da sua frota, apenas uma vez. Não vai mais precisar de 
                      intermediários, de engenheiro, nem tampouco de saber como funciona o processo de obtenção de AET. 
                    </p>
                  </div>
                  
                </div>
              </div>
            </div>

            

            <div class="row">
              <div class="col-lg-6 col-md-6 center">
                <div class="beneficio">
                  <img src="assets/imgs/brasil.jpg" alt="">
                  <p>Você mesmo emite AETs de <strong>vários estados</strong>.</p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 center ">
                <div class="beneficio">
                  <img src="assets/imgs/calendario.jpg" alt="">
                  <p>Recebe alertas próximo aos <strong>vencimentos</strong>.</p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 center">
                <div class="beneficio">
                  <img src="assets/imgs/aets.jpg" alt="">
                  <p><strong>Evita  erros</strong> de preenchimento.</p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 center">
                <div class="beneficio">
                  <img src="assets/imgs/download.jpg" alt="">
                  <p><strong>Salva</strong> todas as AETs e boletos <strong>na nuvem</strong></p>
                </div>
              </div>
            </div>

            <h1 class="center gradient" style="font-size:36pt;margin-top:50px;">Automação,<br> gestão e economia</h1>
            <p class="center"><strong>Ficou interessado?</strong> Preencha o formulário abaixo e receba uma demonstração do sistema e um periodo de teste sem nenhum compromisso.</p>

        </div>
        
        <div class="col-lg-12 col-md-12"></div>

        <div class="col-lg-5 col-md-5">
            <div class="shadow" style="border-radius:10px;padding:25px;margin-top:25px;background:#FFF;">
              <formulario></formulario>
            </div>
        </div>

      </div>
    </div>

    <div style="background:#f9f9f9;padding:50px;margin-top:-100px;padding-top:150px;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-5">

          <div class="" style="font-size:10pt;">
            Este anuncio é destinado a transportadoras
            que querem internalizar o processo de obtenção de
            AETs de maneira tranquila e segura. 
            
            
          </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import formulario from '@/components/Formulario'

export default {
  name: 'App',
  components: {
    formulario
  }
}
</script>

<style>

.gradient{
    font-size: 72px;
    background: -webkit-linear-gradient(20deg, #ff0000, #6600ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-2{
    font-size: 72px;
    background: -webkit-linear-gradient(30deg, #0040ff, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-box{
background: rgba(235,240,255,1);
background: -moz-linear-gradient(-45deg, rgba(235,240,255,1) 0%, rgba(255,222,222,1) 100%);
background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(235,240,255,1)), color-stop(100%, rgba(255,222,222,1)));
background: -webkit-linear-gradient(-45deg, rgba(235,240,255,1) 0%, rgba(255,222,222,1) 100%);
background: -o-linear-gradient(-45deg, rgba(235,240,255,1) 0%, rgba(255,222,222,1) 100%);
background: -ms-linear-gradient(-45deg, rgba(235,240,255,1) 0%, rgba(255,222,222,1) 100%);
background: linear-gradient(135deg, rgba(235,240,255,1) 0%, rgba(255,222,222,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebf0ff', endColorstr='#ffdede', GradientType=1 );
}


</style>
